@import '../base/variables';

.imp-progress {
    position: relative;
    display: block;
    width: 100%;
    height: 0.25rem;
    margin-bottom: 1rem;
    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    z-index: 2;

    &.bg-red {
        background-color: rgba(0, 89, 156, 0.5) !important;

        &.done {
            background-color: $red-500 !important;
        }
    }

    &.tall {
        height: 0.5rem;
    }

    &.bg-primary {
        background-color: rgba(0, 89, 156, 0.5) !important;
    }

    &.bg-primary .indeterminate {
        background-color: $blue-200 !important;
    }

    .indeterminate {
        background-color: $red-500;
    }
    .indeterminate:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: inherit;
        animation: indeterminate 2.5s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        will-change: left, right;
    }
    .indeterminate:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: inherit;
        animation: indeterminate 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
        will-change: left, right;
    }
}

@keyframes indeterminate {
    0% {
        right: 100%;
        left: -35%;
    }

    60% {
        right: -90%;
        left: 100%;
    }

    100% {
        right: -90%;
        left: 100%;
    }
}
