@import '../../ui/base/variables';
@import '../../imperial-overrides';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.search-input {
    font-size: 1rem !important;
    height: 52px !important;
}

.search-input-static {
    font-size: 1rem !important;
    height: calc(1.5em + 1rem + 8px) !important;

    &:focus {
        outline: none !important;
        box-shadow: none !important;
        border-color: $border-color !important;
        border-width: 1px;
    }
}

@include media-breakpoint-up(sm) {
    .search-input {
        font-size: 1.25rem !important;
        height: 52px !important;
    }

    .search-input-static {
        font-size: 18px !important;
        height: calc(1.5em + 1rem + 5px) !important;
    }
}

@include media-breakpoint-up(md) {
    .search-input-static {
        font-size: 20px !important;
        height: calc(1.5em + 1rem + 2px) !important;
    }
}

.searchOverlayWrapper {
    background-color: rgba(51, 51, 51, 0.7);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 3900;
    left: 0;
    top: 0;
}

.clear-input {
    font-size: 24px;
}

.searchOverlay {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;

    z-index: 4000;

    .more-results {
        border-bottom: $borders;
        padding-bottom: 17px;
    }
}

.searchOverlay__searchCategoryResult {
    .card-body {
        padding: 0.5rem 1rem;

        &:hover {
            outline: 1px solid $gray-500;
        }

        a {
            color: $gray-650;
        }
    }
}

.searchOverlay__searchContainer {
    background-color: $white;
    z-index: 400;
    min-height: 500px;
}

.searchOverlay__termsContainer {
    background-color: $white;
    z-index: 400;
    min-height: 500px;
}

.searchOverlay__searchHistoryTemplate ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;

    li:last-child {
        padding-top: 10px;
    }
}

.searchOverlay__searchResults {
    ul {
        padding-left: 30px;
    }

    .productResult__bc {
        margin-top: 4px;

        a {
            font-size: 14px;
            white-space: nowrap;

            & > .svg-inline--fa {
                color: $gray-200;
                margin-left: -3px;
            }
        }
    }
}

.searchOverlay__searchForm {
    z-index: 400;

    .searchForm__cancelButton {
        cursor: pointer;
        color: $gray-650;
    }

    .outline-0:focus {
        background-color: $white;
        outline: none;
        box-shadow: none;
        border-color: $gray-200;
        border-width: 1px;
    }
}

.overlay_head {
    border-top: 1px solid $gray-150;
    margin-bottom: 0;

    .float-right {
        line-height: 42px;
    }

    h5 {
        color: $gray-650;
        padding: 1rem;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

svg.float_arrow {
    position: absolute;
    right: 0;
    top: 0;
}

svg.right-arrow {
    padding: 20px;
    color: $gray-450;
    transform: rotate(225deg);
}

.searchOverlay__fullHistory {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        place-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        border-top: 1px solid $gray-150;

        i {
            color: $gray-450;
        }

        a,
        a:hover {
            text-decoration: none;
            color: $gray-650;
        }
    }

    li:last-of-type {
        border-bottom: 1px solid $gray-150;
    }
}

.product_view {
    border-top: 1px solid $gray-150;

    .productLink,
    .productLink:hover {
        place-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        color: $gray-650;
    }
}

.searchForm__cancelButton {
    padding: 0 1rem;

    .svg-inline--fa {
        font-size: 28px;
        color: $gray-650;
    }
}

@media (max-width: map-get($grid-breakpoints, 'lg')) {
    .overlay_row {
        .card {
            border-radius: 0 !important;
            border: none !important;
            border-top: 1px solid $gray-150 !important;
        }
        .card-body:hover {
            outline: none !important;
        }
    }

    .search-header {
        background-color: $gray-100;
    }

    .searchOverlaySearchResults {
        min-height: 100%;
        background-color: $white;
    }
}

@include media-breakpoint-up(lg) {
    .searchOverlayWrapper {
        background-color: rgba($gray-650, 0.7);
    }
    .searchOverlay {
        top: 46px;
        width: 970px;
    }

    .overlay_head {
        border-top: none;

        h5 {
            color: $gray-400;
            font-weight: 400;
            line-height: 1.2;
            font-size: 1.25rem;
            text-transform: none;
        }
    }

    .overlay_row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;
    }

    .searchOverlay__searchForm {
        .searchForm__cancelButton {
            cursor: pointer;
            width: 30px;
            margin: 0 1rem 0 0;

            .svg-inline--fa {
                color: $gray-150 !important;
            }
        }
    }

    .searchOverlay__searchCategoryResult {
        flex: 0 0 33%;
        max-width: 33%;
        margin-bottom: 1rem;
        position: relative;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;

        .card-body {
            flex: 1 1 auto;
            min-height: 1px;

            a {
                color: #00599c;
            }

            &:hover {
                outline: 1px solid $gray-500;
            }

            .thumbnail-wrapper {
                display: block;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    .searchOverlay__searchContainer {
        padding: 1rem;
    }
    svg.right-arrow {
        display: none !important;
    }
}
@include media-breakpoint-up(xl) {
    .searchOverlay {
        width: 1200px;
    }

    .searchOverlay__searchForm .searchForm__searchInput {
        width: 1028px;
    }
}

[data-react^='SearchOverlay_'] {
    width: 100%;
}

@include media-breakpoint-down(md) {
    .searchOverlay__searchContainer {
        min-height: 100vh;
    }
    .searchOverlay {
        top: 0;
    }
}