@font-face {
    font-family: 'industry-black';
    src: url('ui/industry/industry-black.woff2') format('woff2'), url('ui/industry/industry-black.woff') format('woff');
}

.font-family-industry {
    font-family: 'industry-black';
}

.font-industry-red {
    font-family: 'industry-black';
    color: $red-500;
    font-size: calc(1.75rem + 1.5vw);
    line-height: 0.75;
}
