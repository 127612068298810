@import '../base/variables';

body {
    -webkit-overflow-scrolling: auto;
}

.pointer {
    cursor: pointer;
}

.spinner {
    display: block;
    border: 4px solid $gray-100;
    border-right-color: $gray-250;
    border-left-color: $gray-250;
    border-bottom-color: $gray-250;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 0.75s linear infinite;
    margin: 16px auto;

    &.overlay {
        position: absolute;
        right: 100px;
        top: 2px;
        z-index: 10;
    }

    &.btn-spinner {
        border: 3px solid $gray-350;
        border-top: 3px solid $gray-250;
        width: 28px;
        height: 28px;
    }

    &.centered {
        margin: calc(50% - 32px) auto;
    }

    &.small {
        border: 3px solid $gray-100;
        border-right-color: $gray-250;
        border-left-color: $gray-250;
        border-bottom-color: $gray-250;
        width: 16px;
        height: 16px;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

input.error {
    border-color: #b20f21;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-none {
    overflow-x: hidden;
}

.text-truncate-fade {
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
        z-index: 2;
        pointer-events: none;
    }

    p {
        overflow: hidden;
        text-align: justify;
        white-space: nowrap;
    }
}
