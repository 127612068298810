@import 'imperial-overrides';

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/utilities/borders';
@import '~bootstrap/scss/utilities/background';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/utilities/align';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/screenreaders';
@import '~bootstrap/scss/utilities/position';
@import '~bootstrap/scss/utilities/float';
@import '~bootstrap/scss/utilities/stretched-link';
@import '~bootstrap/scss/utilities/shadows';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/pagination'; // only used on ShopTalk
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/list-group'; // only used on Account info
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/utilities/embed';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/utilities/overflow';
@import '~bootstrap/scss/nav';

@import '@fortawesome/fontawesome-svg-core/styles.css';

@import 'ui/utils/std-include';

.container-xl {
    padding: 0 16px;
}

// Imp Components
.h1-lockup {
    font-weight: 400;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .flex-nowrap;
    @extend .border-bottom;
    @extend .mb-5;

    span {
        @extend .mb-0;
        @extend .h6;
        font-weight: 400;
    }
}

.h4-lockup {
    font-weight: 700;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .flex-wrap;
    @extend .h4;
    @extend .border-bottom;
    @extend .pb-1;
    @extend .mb-3;

    span {
        @extend .pt-2;
        @extend .mb-0;
        @extend .h6;
        font-weight: 400;
    }
}

.body {
    @extend .h6;
}

.text-link {
    @extend .pointer;
    color: $blue-200;
}

.block-header {
    @extend .p-3;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .mt-3;
    @extend .gray-100-bg;
    @extend .h5;
    border-top: 5px solid #d6d6d6;

    &.mt-0 {
        @extend .mt-0;
    }
}

.section-header {
    @extend .py-3;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .mt-3;
    @extend .mb-0;
    @extend .gray-100-bg;
    @extend .h6;
    @extend .border-top;

    &.mt-0 {
        @extend .mt-0;
    }
}

.block-footer {
    @extend .border-bottom;
    @extend .p-3;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .mt-3;
    @extend .gray-100-bg;
    border-top: 5px solid #d6d6d6;
    @extend .font-weight-bolder;
}

.empty-state {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .text-center;
    @extend .w-100;
    color: $gray-400;
    height: 275px;

    &.short {
        height: 75px;
    }
}

.abs-center {
    @extend .empty-state;
    @extend .h-100;
}

.gray-container {
    @extend .gray-100-bg;
    @extend .mb-5;
    @extend .p-3;
}

.card-round {
    @extend .rounded-circle;
    @extend .border;
    @extend .p-3;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .mx-auto;
    width: 175px;
    height: 175px;
}

.card-body {
    padding: 1rem;
}

.dropdown {
    @extend .pointer;
}

.dropdown-button {
    @extend .pointer;
    @extend .text-decoration-none;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

.modal-header {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .gray-600-bg;
    @extend .text-white;
    @extend .py-2;

    button {
        @extend .close;
        @extend .btn;
        @extend .btn-link;
        @extend .text-decoration-none;
    }
}

.modal-footer {
    @extend .border-top-0;
}

.modal-flyout > .modal-dialog {
    @extend .align-items-start;
    @extend .position-fixed;
    @extend .m-0;
    @extend .p-0;
    top: 0;
    right: 0;
    width: 80%;
    max-width: 540px;
    max-height: 100vh !important;
    height: 100vh !important;
    transform: translate3d(-100vw, 0, 0);

    .modal-header {
        @extend .bg-white;
        color: $gray-700 !important;

    }
    .modal-content {
        height: 100vh !important;
        max-height: 100vh !important;
    }
}

h4,
.h4 {
    font-weight: 700;
}

.btn-lg {
    line-height: 1.7 !important;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 6px);
}

.cms-p-0 {
    p {
        margin-bottom: 0;
    }
}

.barcode-button {
    padding: 12px 10px;
    width: 50px;
}

.imp-modal-open {
    overflow: hidden;
}

.modal-content {
    border-radius: 0;
    border: 0 !important;

    .modal-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .modal-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.no-after {
    &::after {
        display: none;
    }
}

.circle-responsive {
    @extend .border;
    border-radius: 50%;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
}

.chip {
    @extend .badge;
    @extend .badge-pill;
    @extend .badge-secondary;
    @extend .text-capitalize;
    @extend .p-2;
    @extend .mr-2;
    @extend .d-flex;
    @extend .align-items-center;
    width: fit-content;
    font-size: 14px;

    i {
        margin-left: 6px;
        margin-top: 1px;
        vertical-align: text-top;
    }
}

.badge {
    a {
        color: inherit;

        &:hover {
            text-decoration: none;
        }

        i {
            position: relative;
            top: -1px;
        }
    }
}

.table p {
    margin-bottom: 0;
}

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
    background-color: transparent!important;
    border: none!important;
    font-size: 1rem!important;
    padding: 0!important;
    color: inherit!important;

    &:hover {
        background-color: transparent!important;
    }
}

@mixin modal-fullscreen() {
    padding: 0 !important; // override inline padding-right added from js

    .modal-dialog:not(.is-dialog) {
        width: 100%;
        max-width: none;
        height: 100%;
        max-height: 100%;
        margin: 0;
        top: 0;

        .modal-content {
            height: 100%;
            border-radius: 0;
        }

        .modal-body {
            overflow-y: auto;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $value in $positions {
            .position#{$infix}-#{$value} {
                position: $value !important;
            }
        }
    }

    @include media-breakpoint-down($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .modal-fullscreen#{$infix} {
            @include modal-fullscreen();
        }
    }
}
.always-fullscreen {
    padding: 0 !important; // override inline padding-right added from js

    .modal-dialog:not(.is-dialog) {
        width: 100%;
        max-width: none;
        height: 100%;
        max-height: 100%;
        margin: 0;
        top: 0;

        .modal-content {
            height: 100%;
            border-radius: 0;
        }

        .modal-body {
            overflow-y: auto;
        }
    }
}

.caption {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3;
}

main {
    min-height: 600px;
}

//Border Mods
.border-5 {
    border-width: 5px !important;
}

.border-top-5 {
    border-top-width: 5px !important;
}

.border-left-5 {
    border-left-width: 5px !important;
}

label {
    &.required::after {
        content: ' *';
    }

    &.optional::after {
        font-size: 0.875rem;
        content: ' [optional]';
        color: $gray-400;
    }

    &.error {
        color: $red-600;
        margin-top: 4px;
        display: block;
    }

    &.success {
        color: $green-200;
        margin-top: 4px;
        display: block;
    }
}

span {
    &.error {
        color: $red-600;
    }
}

.disabled {
    text-decoration: none;
    color: $gray-400;

    &:hover {
        color: $gray-400;
        text-decoration: none;
    }
}

.align-columns-middle {
    td {
        vertical-align: middle;
    }
}

.table thead th,
.table th {
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    border-bottom: none !important;
}

// Radio button mods
.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
    width: 1.5rem;
    height: 1.5rem;
}

.checkbox-lg .custom-control-label {
    padding: 6px 0 4px 12px;
    font-size: 16px;
}

button[type='icon'] {
    border: none;
    background: none;
    width: 20px;
    margin-right: 4px;
    padding: 10px 20px;
}

.custom-switch.custom-switch-lg {
    padding-top: 0.5rem;
    padding-left: 4.5rem;
    height: 38px;

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        background-color: $green-100 !important;
        border-color: $green-100 !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: $white !important;
        border-color: $green-200 !important;
        background-color: $green-200 !important;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(3, 193, 3, 0.25);
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(19px);
    }

    > label:before {
        top: -0.3rem;
        left: -47px;
        width: 51px;
        height: calc(2.5rem - 8px) !important;
        border-radius: 2rem;
    }

    > label:after {
        top: calc(-0.25rem + 2px) !important;
        left: calc(-2.25rem + -8px) !important;
        width: calc(2.47rem - 13px) !important;
        height: calc(2.47rem - 13px) !important;
        border-radius: 1rem !important;
    }
}

label.error {
    @extend .text-red;
}

label.success {
    color: $green-200;
}

.badge {
    text-transform: uppercase;
}

.fa-lg {
    font-size: (4em / 3);
    line-height: (3em / 4);
    vertical-align: top;
}

.fa-2x {
    font-size: 20px;
}

.fa-25x {
    font-size: 26px;
}

.fa-3x {
    font-size: 2em;
}

.fa-4x {
    font-size: 3em;
}

.fa-5x {
    font-size: 4em;
}

.feedback-button-global {
    position: fixed;
    bottom: 90px !important;
    top: auto !important;
    z-index: 1029 !important;
    cursor: pointer !important;
    right: 0 !important;

    img {
        width: 60px;
        height: 61px;
        margin: 0;
        padding: 0;
        border: 0;
        overflow: hidden;
        z-index: 9998;
        left: 0;
        top: 0;
        box-shadow: 0 0 0;
        background-color: transparent;
    }
}

.livechat_button {
    position: fixed;
    bottom: 20px;
    z-index: 1029 !important;
    cursor: pointer !important;
    right: 7px !important;

    img {
        height: 60px;
        width: 60px;
    }
}

.custom-control-input:disabled ~ .custom-control-label {
    cursor: not-allowed;
}

.disabled-button-wrapper {
    cursor: not-allowed;

    .btn:disabled,
    select:disabled {
        pointer-events: none;
    }
}

.alert {
    display: flex;
    align-items: center;

    &.alert-multiline span {
        white-space: pre-wrap;
    }

    &.alert-multiline div {
        white-space: pre-wrap;
    }

    > .fa {
        margin-right: 16px;
    }
}

a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

main:focus {
    outline: 0;
}

.table-align-middle td {
    @extend .align-middle;
}

// for hdpi screens (example Galaxy S8)
@media only screen and (-webkit-device-pixel-ratio: 4) {
    .quick-add-input {
        width: 200px;
    }
}

@media print {
    @page {
        size: 330mm 427mm;
        margin: 14mm;
    }
    .container {
        width: 1270px;
    }
}

@include media-breakpoint-up(lg) {
    .sticky-lg-top {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1024;
    }
}
@include media-breakpoint-up(md) {
    .modal-dialog{
        width: 80vw;
        max-width: 920px !important;
    }
    .modal-dialog.is-dialog {
        width: 80vw;
        max-width: 920px;
        margin: 1.75rem auto !important;
    }
}

@include media-breakpoint-down(md) {
    .return-to-top {
        display: none !important;
    }

    .sticky-md-top {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1025;
    }

    .position-sm-relative {
        position: relative;
    }

    .position-sm-top-right {
        position: absolute !important;
        top: 16px;
        right: 16px;
    }

    .gray-100-bg-md {
        background-color: $gray-100;
    }

    .block-header-mobile {
        background-color: $gray-150 !important;
        border-top-color: $gray-250 !important;
    }

    @media only screen {
        .border-md {
            border: 1px solid $gray-150;
        }

        .table-card {
            empty-cells: hide;

            thead {
                display: none;
            }

            tr {
                border: 1px solid $gray-150;
                border-radius: 3px;
                padding: 1.25rem;
                margin-bottom: 16px;
                float: left;
                width: 100%;
                display: block;
            }

            tfoot tr {
                border: 0;
                border-bottom: 5px solid $gray-150;
                background-color: $gray-100;
                padding: 1rem;
                float: none;

                td {
                    border: 0;
                }
            }

            tfoot.tfoot-card {
                td {
                    border: 0 !important;
                    display: table;
                    padding: 0 0 0 45% !important;
                    line-height: 2 !important;
                    position: relative;
                    width: 100%;

                    &:first-of-type::after {
                        visibility: hidden;
                    }

                    &:empty {
                        display: none;
                    }
                }

                td[data-title] {
                    &:before {
                        content: attr(data-title);
                        font-weight: 700;
                        display: table-cell;
                        width: 45%;
                        padding-left: 16px;
                    }
                }
            }

            tbody td:not([data-title]) {
                border: 0 !important;
                display: block;
                padding: 0 !important;
                line-height: 2 !important;
                position: relative;

                &:first-of-type::after {
                    visibility: hidden;
                }
            }

            tbody td[data-title] {
                border: 0 !important;
                display: table;
                padding: 0 0 0 45% !important;
                line-height: 2 !important;
                position: relative;
                width: 100%;

                &:first-of-type::after {
                    visibility: hidden;
                }

                &:empty {
                    display: none;
                }
            }

            tbody td[data-title] {
                &:before {
                    content: attr(data-title);
                    font-weight: 700;
                    display: table-cell;
                    height: 100%;
                    left: 0;
                    margin: auto;
                    vertical-align: middle;
                    white-space: nowrap;
                    width: 45%;
                }
            }
        }
    }

    .container-md-fluid {
        width: 100%;
        max-width: none;
        padding-right: 0;
        padding-left: 0;
    }

    .divOverlay {
        top: 0 !important;
    }

    .dropdown-menu.show {
        z-index: 1260;
    }

    .search-bar {
        display: none;
    }

    tr:not(.keep-sort) [class^='fa fa-sort'] {
        display: none;
    }

    .searchBtn {
        background-color: $white;
        border-color: $gray-200;
        color: $gray-400;
    }
}

@include media-breakpoint-up(sm) {
    .border-sm-top {
        border-top: 1px solid $border-color;
    }
}

@include media-breakpoint-down(sm) {
    .fixed-sm-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }

    .gray-100-bg-sm {
        background-color: $gray-100;
    }

    .border-bottom-sm-0 {
        border-bottom: none;
    }

    .w-sm-100 {
        width: 100% !important;
    }
}

@include media-breakpoint-down(xs) {
    .border-xs-top {
        border-top: 1px solid $border-color !important;
    }
}

@include media-breakpoint-up(lg) {

    .border-lg {
        border: 1px solid $gray-150;
    }

    .modal-dialog.is-dialog {
        width: 40vw;
    }

    .modal-dialog {
        width: 80vw;
        max-width: 920px;
    }

    .search-bar {
        display: block;
    }

    a.skip-main:focus,
    a.skip-main:active {
        left: auto;
        top: auto;
        width: 180px;
        height: auto;
        overflow: auto;
        margin: 10px auto;
        padding: 5px;
        text-align: center;
        font-size: 1.2em;
        z-index: 999;
        display: block;
        position: initial;
    }
}

.sticky-top {
    position: -webkit-sticky;
}


ul.products {
    list-style: none;
    margin: 0;
    padding: 0;
    column-count: 3;
    column-gap: 20px;

    &.hamburger {
        li {
            margin-bottom: 1rem;
        }

        img {
            margin-right: 1rem;
        }
    }

    li {
        line-height: 26px;
    }
}

.breadcrumbs-wrapper {
    padding: 0 0 8px 10px;
    overflow-y: auto;

    .fa {
        color: $gray-550;
    }

    .fa-angle-right {
        font-size: 12px;
        padding: 0 4px;
    }
}

.divOverlay {
    background-color: $gray-650;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
    filter: alpha(opacity=50);
    opacity: 0.5;
    height: 100%;
    bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1026;
    display: none;
    left: 0;
    right: 0;
}

.no-scroll {
    overflow: hidden !important;
    position: relative;
    height: 100%;
    margin-right: 15px;
}

.return-to-top {
    position: fixed;
    bottom: 166px;
    right: 8px;
    background: $gray-500;
    width: 60px;
    height: 60px;
    text-decoration: none;
    display: none;
    border: 0;
    border-radius: 3px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 8px;
    z-index: 1029;

    &:hover {
        background: $gray-550;
    }
}

.return-to-top i {
    color: $white;
    margin: 0;
    position: relative;
    top: 1px;
    font-size: 24px;
}

.return-to-top span {
    color: $white;
    margin: 6px 0 0;
    display: block;
    position: relative;
    font-size: 14px;
}

// enter debug header
[data-component='debug-console'] {
    .debugConsole {
        padding: 8px;
    }

    .fa-expand-arrows {
        position: absolute;
        top: 6px;
        left: 16px;
        z-index: 2000;
    }
}

.center-radio:before,.center-radio:after{
    top: 50%;
    margin-top: -11px;
}

// Responsive Mixin Helper  Usage md:btn-lg
$sizes: ('xs', 'sm', 'md', 'lg', 'xl');
@each $size in $sizes {
    @include media-breakpoint-up(#{$size}) {
        .#{$size}\:btn-lg {
            @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
        }
    }
}